<template>
  <div>
    <el-badge
      v-if="hasChose.length != 0"
      :value="hasChose.length"
      class="item showList"
    >
      <el-button
        class="btns"
        icon="el-icon-shopping-cart-full"
        circle
      ></el-button>
    </el-badge>
    <div class="shop-list">
      <div class="shop-item" v-for="(item,index) in array" :key="index">
        <div class="shop-item-l ellipsisText">
          <p class="title">{{item.name}}</p>
          <p class="categroy">{{item.category_name}}</p>
          <p class="info ellipsisText" :title="item.subject">
            {{item.subject}}
          </p>
          <p class="num">{{item.serial_number}}</p>
          <div class="detail-btn" @click="gotoDetail(item)">了解更多</div>
        </div>
        <div class="shop-item-r">
          <img :src="item.file && item.file.image_url" alt="" />
        </div>
      </div>
    </div>
    <div class="footers">
      <fwPageNumber :pages="page" @changePage="changePage" />
    </div>
  </div>
</template>

<script>
import fwPageNumber from "@/components/pageNumber.vue";
import { get_sku_list } from "@/assets/api/index.js";
import { post_buy_cart, post_buyer_cart } from "@/assets/api/detailsApi.js";
export default {
  components: {
    fwPageNumber,
  },
  props: {
    showType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      hasChose: [],
      styleObj: {
        color: "#333",
        "background-color": "#f5f5f5",
      },
      array: [],
      searchData: null,
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
    };
  },
  created() {},
  methods: {
    enterpriseCar() {
      console.log(this.array);
      let token = "";
      if (process.env.NODE_ENV === "production") {
        token = sessionStorage.getItem("AccessToken");
      } else {
        token = localStorage.getItem("AccessToken");
      }

      let buy_cart_list = this.array.filter((i) => {
        if (i.checked) {
          return i;
        }
      });
      buy_cart_list = buy_cart_list.map((i) => {
        return {
          buy_count: i._value,
          seller_company_id: i.company_id,
          com_sku_id: i.id,
        };
      });
      console.log(buy_cart_list);
      if (token) {
        post_buy_cart({ buy_cart_list }).then((res) => {
          this.$message.success("添加成功");
        });
      } else {
        localStorage.setItem("buy_cart_list", JSON.stringify(buy_cart_list));
        let querys = JSON.stringify(this.$route.query);
        window.open(
          location.origin + `/login?url=/_shop/search?query=${querys}`
        );
      }
    },
    // 个人购物车
    myCar(val) {
      let token = "";
      if (process.env.NODE_ENV === "production") {
        token = sessionStorage.getItem("AccessToken");
      } else {
        token = localStorage.getItem("AccessToken");
      }
      let buy_cart_list = this.array.filter((i) => {
        if (i.checked) {
          return i;
        }
      });
      buy_cart_list = buy_cart_list.map((i) => {
        return {
          buy_count: i._value,
          seller_company_id: i.company_id,
          com_sku_id: i.id,
          is_choice: 1,
        };
      });
      if (token) {
        post_buyer_cart({ buy_cart_list }).then((res) => {
          this.$message.success("添加成功");
          if (val) {
            location.href = location.origin + `/confirmOrder`;
          }
        });
      } else {
        localStorage.setItem("buy_cart_list", JSON.stringify(buy_cart_list));
        let querys = JSON.stringify(this.$route.query);
        window.open(
          location.origin + `/login?url=/_shop/search?query=${querys}`
        );
      }
    },
    contrast() {
      // 对比
      if (this.hasChose.length == 0) {
        this.$message.error("请先选择对比数据");
      } else if (this.hasChose.length >= 5) {
        this.$message.error("最多选择4条对比数据");
      } else {
        let array = [];
        this.hasChose.forEach((item) => {
          array.push({
            id: item.id,
            spec_value_id: item.spec_value_id,
          });
        });
        this.$router.push({
          path: "/contrast",
          query: {
            ids: JSON.stringify(array),
          },
        });
      }
    },
    choseImg(row) {
      row.checked = !row.checked;
      this.changeChecked(row.checked, row);
    },
    changeChecked(val, item) {
      if (val) {
        this.hasChose.push(item);
      } else {
        let index = this.hasChose.findIndex((it) => {
          return item.id == it.id;
        });
        if (index > -1) {
          this.hasChose.splice(index, 1);
        }
      }
    },
    changePage(val) {
      if (val.type == "size") {
        this.page.pageSize = val.num;
      } else {
        this.page.pageNum = val.num;
      }
      this.query(this.searchData);
    },
    gotoDetail(val) {
      // console.log(val.spec_value_id)
      // return
      this.$router.push({
        path: "/shopStore/detail",
        query: {
          id: val.id,
          category_id: val.category_id,
        },
      });
    },
    getQuery(val) {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.query({ ...this.searchData, ...val });
    },
    // 商品列表
    query(parma) {
      this.searchData = parma;
      get_sku_list({
        ...parma,
        page: this.page.pageNum,
        page_size: this.page.pageSize,
      }).then((res) => {
        this.array = res.data.data.map((item) => {
          let booles = this.hasChose.findIndex((its) => {
            return its.id == item.id;
          });
          item.checked = booles > -1;
          item._value = 1.0;
          return item;
        });
        this.page.total = res.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cus {
  cursor: pointer;
}
.titleName {
  &:hover {
    color: $primary;
  }
}
.footers {
  padding: 0 10px;
  background-color: $white;
}
.showImgs {
  width: 88px;
  height: 88px;
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;
  .checkeds {
    position: absolute;
    right: 4px;
    top: 0px;
  }
}
.choseImg {
  border: 1px solid $primary !important;
}
.links {
  & > b {
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}

.showList {
  position: fixed;
  left: 30px;
}
/deep/ .btns {
  i {
    font-size: 16px !important;
  }
}
.shop-list {
  width: 100%;
  .shop-item {
    width: 100%;
    // height: 296px;
    background-color: #ffffff;
    border: 1px solid rgba(204, 204, 204, 0.4);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 32px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .shop-item-l {
      flex: 1;
      padding-right: 32px;
      .title {
        color: rgba(0, 0, 0, 1);
        font-size: 20px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
      .categroy {
        color: rgba(16, 16, 16, 1);
        font-size: 18px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
        padding: 16px 0;
      }
      .info {
        width: 100%;
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
      .num {
        color: rgba(102, 102, 102, 1);
        font-size: 16px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
        padding: 16px 0 24px;
      }
      .detail-btn {
        width: 122px;
        height: 30px;
        line-height: 23px;
        border-radius: 50px;
        background-color: rgba(255, 255, 255, 1);
        color: $primary;
        font-size: 16px;
        text-align: center;
        line-height: 30px;
        font-family: Roboto;
        border: 1px solid $primary;
        cursor: pointer;
      }
    }
    .shop-item-r {
      width: 160px;
      height: 160px;
      & > img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
  }
}
</style>
