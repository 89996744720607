<template>
  <div>
    <div class="flex-box">
      <div class="back-box" @click="$router.back()">
        <img src="@/assets/img/mesCenter/back.png">返回
      </div>
      <div class="searchData" v-if="this.search.pid || this.search.id">
        <div>
          <div>平台类目：</div>
          <div>
            <span @click="showShangji" class="spnLeimu">{{ search.pname }}</span>
            <span @click="showerji" v-if="search.id" class="spnLeimu">>{{ search.name }}
            </span>
            <span v-if="search.cid" class="spnLeimu">>{{ search.cname }}</span>
          </div>
        </div>
        <div v-if="nextLeimu.length != 0">
          <div>下级类目：</div>
          <div class="leim">
            <span @click="choeThis(item)" class="nexLeimu" v-for="item in nextLeimu" :key="item.id">{{ item.name }}</span>
          </div>
        </div>
        <!-- <div>
        <div>商城品牌：</div>
        <div :class="pingpaiOpne ? '' : 'fixHeighe'">
          <div>
            <el-checkbox
              @change="changepingpaide"
              v-if="pingpaiList.length != 0"
              style="margin-bottom: 4px"
              v-model="pingpaiChecked"
              >全部
            </el-checkbox>
            <el-checkbox
              @change="
                (val) => {
                  changeShangecheng(val, item);
                }
              "
              style="margin-bottom: 4px"
              v-for="item in pingpaiList"
              v-model="item.checked"
              :key="item.id"
              >{{ item.name }}</el-checkbox
            >
          </div>
          <div class="getmore" @click="pingpaiOpne = !pingpaiOpne">
            更多 <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div> -->

        <div v-if="shopProject.length != 0 && nextLeimu.length == 0">
          <div>商品分类</div>
          <div :class="shopProjectOpne ? '' : 'fixHeighe'">
            <div>
              <el-checkbox @change="changeShopProjectAll" style="margin-bottom: 4px" v-model="shopProjectChecked">全部
              </el-checkbox>
              <el-checkbox @change="changeShopProject(item)" style="margin-bottom: 4px" v-for="item in shopProject" v-model="item.checked" :key="item.id">{{ item.name }}</el-checkbox>
            </div>
            <div class="getmore" @click="shopProjectOpne = !shopProjectOpne">
              更多 <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div>

        <div v-if="shopProjectChild.length != 0 && nextLeimu.length == 0">
          <div>
            <!-- 商品分类下级 -->
          </div>
          <div :class="shopProjectOpneChild ? '' : 'fixHeighe'">
            <div>
              <el-checkbox style="margin-bottom: 4px" v-model="shopProjectCheckedChild">全部
              </el-checkbox>
              <el-checkbox @change="changeShopProjectCheckedChild(item)" style="margin-bottom: 4px" v-for="item in shopProjectChild" v-model="item.checked" :key="item.id">{{ item.name }}</el-checkbox>
            </div>
            <div class="getmore" @click="shopProjectOpneChild = !shopProjectOpneChild">
              更多 <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div>

        <template v-if="nextLeimu.length == 0">
          <div v-for="item in guigeList" :key="item.id">
            <div>{{ item.name }}：</div>
            <div :class="item.guigeOpne ? '' : 'fixHeighe'">
              <div>
                <el-checkbox @change="changeUpdate" style="margin-bottom: 4px" v-for="its in item.spec_value" v-model="its.checked" :key="its.id">{{ its.spec_value_name }}{{ its.spec_unit_name }}</el-checkbox>
              </div>
              <div class="getmore" @click="item.guigeOpne = !item.guigeOpne">
                更多 <i class="el-icon-arrow-down"></i>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- <div class="othersSearch">
      <div class="leftSearch">
        排序：
        <span :class="leftSearch.all ? 'chose' : ''">全部</span>
        <span @click="changeNew" :class="leftSearch.news ? 'chose' : ''">{{
          '最新'
        }}</span>
        <span
          >价格
          <i
            @click="chengaPrice('asc')"
            :class="[
              'el-icon-caret-top',
              leftSearch.price == 'asc' ? 'chose' : '',
            ]"
          ></i>
          <i
            @click="chengaPrice('desc')"
            :class="[
              'el-icon-caret-bottom',
              leftSearch.price == 'desc' ? 'chose' : '',
            ]"
          ></i>
        </span>
      </div>
      <div class="twos">
        <i
          @click="changeshowType(1)"
          :class="['el-icon-postcard', showType == 1 ? 'chose' : '']"
        ></i>
        <i
          @click="changeshowType(2)"
          :class="['el-icon-s-operation', showType == 2 ? 'chose' : '']"
        ></i>
        <el-button @click="contrast" style="margin-left: 20px" type="primary">对比</el-button>
        <el-button @click="enterpriseCar" type="primary" plain
          >企业 <i class="el-icon-shopping-cart-full"></i>
        </el-button>
        <el-button @click="myCar(false)" type="warning" plain
          >个人 <i class="el-icon-shopping-cart-full"></i>
        </el-button>
        <el-button @click="myCar(true)" type="warning">立即下单</el-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  get_spec,
  get_shop_category,
  get_brand,
  get_plat_shop_category,
} from "@/assets/api/index.js";

export default {
  props: {
    search: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showType: 1,
      leftSearch: {
        all: true,
        news: false,
        price: "asc", // asc=正序desc=倒序
      },
      nextLeimu: [],

      pingpaiList: [],
      pingpaiOpne: false,
      pingpaiChecked: true,

      guigeOpne: false,
      guigeList: [],

      shopProject: [],
      shopProjectChecked: true,
      shopProjectOpne: false,

      shopProjectChild: [],
      shopProjectCheckedChild: true,
      shopProjectOpneChild: false,
    };
  },
  created() {
    if (this.search.pid || this.search.id || this.search.cid) {
      this.getNextLeimu();
      // 获取品牌
      // this.getPingpai();
    }
  },
  mounted() {
    // 获取列表
    this.changeUpdate();
  },
  methods: {
    // 企业
    enterpriseCar() {
      this.$emit("enterpriseCar");
    },
    // 个人
    myCar(val) {
      this.$emit("myCar", val);
    },
    changeShopProjectAll(val) {
      if (val) {
        this.shopProject.forEach((items) => {
          items.checked = false;
        });
      }
      this.changeUpdate();
    },
    changepingpaide(val) {
      if (val) {
        this.pingpaiList.forEach((item) => {
          item.checked = false;
        });
        this.changeShangecheng();
      }
      this.changeUpdate();
    },
    changeShopProjectCheckedChild() {
      let array = [];
      this.shopProjectChild.forEach((item) => {
        if (item.checked) {
          array.push(item.id);
        }
      });
      if (array.length == 1) {
        this.getguige(array);
      } else {
        this.guigeList = [];
      }

      if (array.length == 0) {
        this.shopProjectCheckedChild = true;
      } else {
        this.shopProjectCheckedChild = false;
      }
    },
    changeShopProject() {
      let array = [];
      this.shopProject.forEach((its) => {
        if (its.checked) {
          array.push(its);
        }
      });
      console.log(array);
      if (array.length == 1) {
        this.shopProjectChild = array[0].children.map((it) => {
          it.checked = false;
          return it;
        });
      } else {
        this.shopProjectChild = [];
        this.guigeList = [];
      }

      if (array.length == 0) {
        this.shopProjectChecked = true;
      } else {
        this.shopProjectChecked = false;
      }
      this.changeUpdate();
    },
    GET_plat_shop_category(val) {
      get_plat_shop_category({ brand_id: val }).then((res) => {
        this.shopProject = res.data;
      });
    },
    changeShangecheng() {
      let array = [];
      this.pingpaiList.forEach((item) => {
        if (item.checked) {
          array.push(item.id);
        }
      });
      console.log(array);
      if (array.length == 1) {
        this.GET_plat_shop_category(array);
      } else {
        this.shopProject = [];
      }
      if (array.length == 0) {
        this.pingpaiChecked = true;
      } else {
        this.pingpaiChecked = false;
      }
      this.changeUpdate();
    },
    // 点击上机类目
    showShangji() {
      this.search.id = "";
      this.search.name = "";
      this.search.cid = "";
      this.search.cname = "";
      this.getNextLeimu();
      this.changeUpdate();
      // 获取品牌
      // this.getPingpai();
    },
    showerji() {
      this.search.cid = "";
      this.search.cname = "";
      this.getNextLeimu();
      this.changeUpdate();
      // 获取品牌
      // this.getPingpai();
    },
    // 对比
    contrast() {
      this.$emit("contrast");
    },
    changeshowType(val) {
      if (this.showType == val) return;
      this.showType = val;
      this.$emit("changeshowType", val);
    },
    chengaPrice(val) {
      this.leftSearch.price = val;
      this.changeUpdate();
    },
    changeUpdate() {
      let spec_value_id = [];
      this.guigeList.forEach((item) => {
        item.spec_value.forEach((its) => {
          if (its.checked) {
            spec_value_id.push(its.spec_value_id);
          }
        });
      });

      let brand_id = [];
      if (!this.pingpaiChecked) {
        this.pingpaiList.forEach((item) => {
          if (item.checked) {
            brand_id.push(item.id);
          }
        });
      }
      // shopProject  商品分类一级
      // shopProjectChild  商品分类下一级
      // 这一段逻辑：如果一级只选了一个，就把他的下级勾选了，或者全选的，都传递过去；
      //            如果一级勾选了两个，就把他的下级全部传递过去
      let saveData = [];
      let shop_category_id = [];
      this.shopProject.forEach((item) => {
        if (item.checked) {
          saveData.push(item);
        }
      });
      if (saveData.length == 1) {
        if (this.shopProjectChild.length > 0) {
          if (this.shopProjectCheckedChild) {
            this.shopProjectChild.forEach((its) => {
              shop_category_id.push(its.id);
            });
          } else {
            this.shopProjectChild.forEach((its) => {
              if (its.checked) {
                shop_category_id.push(its.id);
              }
            });
          }
        }
      } else if (saveData.length > 1) {
        saveData.forEach((item) => {
          item.children.forEach((its) => {
            shop_category_id.push(its.id);
          });
        });
      }

      let plat_category_id = []; //平台类目id
      if (this.search.cid || this.search.id || this.search.pid) {
        plat_category_id = [
          this.search.cid || this.search.id || this.search.pid,
        ];
      }

      let params = {
        brand_id, //商城品牌id
        plat_category_id, //平台类目id
        shop_category_id, //商品分类末级分类id，可能多选
        is_new_added: this.leftSearch.news ? 1 : null, //是否勾选最近一个星期上架的商品
        order: {
          price: this.leftSearch.price, //价格，asc=正序desc=倒序
        },
        name: this.search.search || "", //顶部搜索框 商品名称搜索
        spec_value_id, // 规格值id
      };
      document.title = `${this.$store.state.name_logo.name}
        ${this.search.pname ? "-" + this.search.pname : ""}
        ${this.search.name ? "-" + this.search.name : ""}
        ${this.search.cname ? "-" + this.search.cname : ""}
        `;
      this.$emit("changeUpdate", params);
    },
    // 更改最新
    changeNew() {
      this.leftSearch.news = !this.leftSearch.news;
      this.changeUpdate();
    },
    // 获取规格
    getguige(shop_category_id) {
      get_spec({
        shop_category_id,
      }).then((res) => {
        console.log(res);
        this.guigeList = res.data.map((item) => {
          item.guigeOpne = false;
          item.spec_value.forEach((item) => {
            item.checked = false;
          });
          return item;
        });
        console.log(this.guigeList);
      });
    },
    getPingpai() {
      // 获取品牌
      get_brand({
        company_id: this.$store.state.cid,
        plat_category_id: [
          this.search.cid || this.search.id || this.search.pid,
        ],
      }).then((res) => {
        this.pingpaiList = res.data;
      });
    },
    getNextLeimu() {
      get_shop_category({
        company_id: this.$store.state.cid,
        plat_category_id: this.search.cid || this.search.id || this.search.pid,
      }).then((res) => {
        this.nextLeimu = res.data[0].children || [];
        if (this.nextLeimu.length > 0) {
          let array = [];
          this.nextLeimu.forEach((items) => {
            array.push(items.id);
          });
        }
      });
    },
    choeThis(item) {
      console.log(item);
      if (this.search.id) {
        this.search.cid = item.id;
        this.search.cname = item.name;
      } else {
        this.search.id = item.id;
        this.search.name = item.name;
      }
      this.changeUpdate();
      this.getNextLeimu();
      this.shopProject = [];
      this.nextLeimu = [];

      // 获取品牌
      // this.getPingpai();
    },
  },
};
</script>

<style lang="scss" scoped>
.othersSearch {
  margin-top: 10px;
  background-color: $white;
  padding: 16px 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  & > div {
    display: flex;
    align-items: center;
  }

  .twos {
    & > i {
      color: $textSecondary;
      margin-left: 18px;
      font-size: 18px;
      cursor: pointer;
      &:hover {
        color: $primary;
      }
    }
  }
}
.searchData {
  width: 80%;
  background-color: $white;
  padding: 16px 77px;
  & > div {
    display: flex;
    margin-bottom: 14px;
    & > div:nth-child(1) {
      width: 100px;
    }
    & > div:nth-child(2) {
      flex: 1;
      overflow: hidden;
      padding-bottom: 5px;
      border-bottom: 1px solid #eee;
      display: flex;
      & > div:nth-child(1) {
        flex: 1;
        overflow: hidden;
      }
      & > div:nth-child(2) {
        width: 60px;
        text-align: right;
        color: #000;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}

.fixHeighe {
  height: 23px;
}

.nexLeimu {
  margin-right: 16px;
  height: 30px;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: $primary;
  }
}
.getmore {
  &:hover {
    color: $primary !important;
  }
}

.leftSearch {
  & > span {
    margin-left: 20px;
    user-select: none;
    cursor: pointer;
    i {
      color: $textSecondary;
    }
  }
}

.chose {
  color: $primary !important;
}
.spnLeimu {
  height: 30px;
  cursor: pointer;
  &:hover {
    color: $primary !important;
  }
}
.flex-box {
  display: flex;
  .back-box {
    padding-top: 18px;
    cursor: pointer;
    color: #999;
    display: flex;
    // align-items: center;
    user-select: none;
    & > img {
      width: 16px;
      height: 16px;
      margin-top: 2px;
    }
  }
}
.leim{
  display: flex;
  flex-wrap: wrap;
}
</style>
