<template>
  <!-- <div class="contents" v-if="$store.state.customData.style == 1"> -->
  <div class="cont-box">
    <div class="contents">
      <searchHeader @changeUpdate="changeUpdate" style="margin-bottom: 10px" />
      <apaas v-for="item in apass" :key="item.id" :page="item.page_id" />
      <!-- 租户搜索 -->
      <searchData v-if="$store.state.cid > 0" @contrast="contrast" @enterpriseCar="enterpriseCar" @myCar="myCar" @changeshowType="
          (val) => {
            showType = val;
          }
        " @changeUpdate="changeUpdate" :search="search" style="margin-bottom: 10px" />
      <!-- 平台搜索 -->
      <searchDataPl v-else @contrast="contrast" @enterpriseCar="enterpriseCar" @myCar="myCar" @changeshowType="
          (val) => {
            showType = val;
          }
        " @changeUpdate="changeUpdate" :search="search" style="margin-bottom: 10px" />
      <list :showType="showType" ref="list" />
    </div>
  </div>
  <!-- <div v-else>
    <searchModel />
    <apaas style="margin: 10px 0" :page="0" />
  </div> -->
</template>

<script>
import { get_sku_apaas } from "@/assets/api/index.js";
import list from "./components/list.vue";
import apaas from "../index/components/apaas.vue";
import searchModel from "../index/components/searchModel.vue";
import searchHeader from "../index/components/searchTitles.vue";
import searchData from "./components/searchData.vue";
import searchDataPl from "./components/searchDataPl.vue";
export default {
  name: "searchList",
  components: {
    list,
    searchHeader,
    searchModel,
    apaas,
    searchData,
    searchDataPl,
  },
  data() {
    return {
      showType: 1,
      search: {},
      page_id: 0,
      plat_category_id: null,
      apass: [],
    };
  },
  created() {
    console.log(789);
    this.search = JSON.parse(JSON.stringify(this.$route.query));
    this.plat_category_id = this.$route.query.pid;
    this.getCaterApass();
    // 获取apass
  },
  methods: {
    getCaterApass() {
      get_sku_apaas({ plat_category_id: this.plat_category_id }).then((res) => {
        if (res.code === 200) {
          this.apass = res.data;
        }
        // this.page_id = res.data?.apaas?.page_id || 0;
      });
    },
    changeUpdate(val) {
      console.log(val);
      this.plat_category_id = val.plat_category_id[0];
      this.getCaterApass();
      this.$refs.list.getQuery(val);
    },
    contrast() {
      // 对比
      this.$refs.list.contrast();
    },
    myCar(val) {
      this.$refs.list.myCar(val);
    },
    enterpriseCar() {
      this.$refs.list.enterpriseCar();
    },
  },
};
</script>
<style lang="scss" scoped>
.cont-box {
  width: 100%;
  height: 100%;
  background: #fff;
}
.contents {
  width: 1200px;
  margin: 0 auto;
}
</style>
