<template>
  <div>
    <div class="searchData" v-if="this.search.pid || this.search.id">
      <div>
        <div>{{ $t("商品分类") }}：</div>
        <div>
          <span @click="showShangji" class="spnLeimu">{{ search.pname }}</span>
          <span class="spnLeimu">>{{ search.name }} </span>
        </div>
      </div>
      <div v-if="nextLeimu.length != 0">
        <div>{{ $t("下级分类") }}：</div>
        <div>
          <span
            @click="choeThis(item)"
            class="nexLeimu"
            v-for="item in nextLeimu"
            :key="item.id"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <div>
        <div>{{ $t("商品品牌") }}：</div>
        <div :class="pingpaiOpne ? '' : 'fixHeighe'">
          <div>
            <el-checkbox
              @change="changepingpaiListAll"
              v-if="pingpaiList.length != 0"
              style="margin-bottom: 4px"
              v-model="pingpaiChecked"
              >{{ $t("全部") }}
            </el-checkbox>
            <el-checkbox
              @change="changepingpaiList"
              style="margin-bottom: 4px"
              v-for="item in pingpaiList"
              v-model="item.checked"
              :key="item.id"
              >{{ item.value }}</el-checkbox
            >
          </div>
          <div class="getmore" @click="pingpaiOpne = !pingpaiOpne">
            {{ $t("更多") }} <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
      <template v-if="this.search.id">
        <div v-for="item in guigeList" :key="item.id">
          <div>{{ item.name }}：</div>
          <div :class="item.guigeOpne ? '' : 'fixHeighe'">
            <div>
              <el-checkbox
                @change="changeUpdate"
                style="margin-bottom: 4px"
                v-for="its in item.spec_value"
                v-model="its.checked"
                :key="its.id"
                >{{ its.spec_value_name }}{{ its.spec_unit_name }}</el-checkbox
              >
            </div>
            <div class="getmore" @click="item.guigeOpne = !item.guigeOpne">
              {{ $t("更多") }} <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="othersSearch">
      <div class="leftSearch">
        {{ $t("排序") }}：
        <span :class="leftSearch.all ? 'chose' : ''">{{ $t("全部") }}</span>
        <span @click="changeNew" :class="leftSearch.news ? 'chose' : ''">{{
          $t("最新")
        }}</span>
        <span
          >{{ $t("价格") }}
          <i
            @click="chengaPrice('asc')"
            :class="[
              'el-icon-caret-top',
              leftSearch.price == 'asc' ? 'chose' : '',
            ]"
          ></i>
          <i
            @click="chengaPrice('desc')"
            :class="[
              'el-icon-caret-bottom',
              leftSearch.price == 'desc' ? 'chose' : '',
            ]"
          ></i>
        </span>
      </div>
      <div class="twos">
        <i
          @click="changeshowType(1)"
          :class="['el-icon-postcard', showType == 1 ? 'chose' : '']"
        ></i>
        <i
          @click="changeshowType(2)"
          :class="['el-icon-s-operation', showType == 2 ? 'chose' : '']"
        ></i>
        <el-button @click="contrast" style="margin-left: 20px" type="primary">{{
          $t("对比")
        }}</el-button>
        <el-button @click="enterpriseCar" type="primary" plain
          >{{ $t("企业") }} <i class="el-icon-shopping-cart-full"></i>
        </el-button>
        <el-button @click="myCar(false)" type="warning" plain
          >{{ $t("个人") }} <i class="el-icon-shopping-cart-full"></i>
        </el-button>
        <el-button @click="myCar(true)" type="warning">{{
          $t("立即下单")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { get_spec, get_shop_category, get_brand } from "@/assets/api/index.js";
export default {
  props: {
    search: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showType: 1,
      leftSearch: {
        all: true,
        news: false,
        price: "asc", // asc=正序desc=倒序
      },
      nextLeimu: [],

      pingpaiList: [],
      pingpaiOpne: false,
      pingpaiChecked: true,

      guigeOpne: false,
      guigeList: [],
    };
  },
  created() {
    if (this.search.id) {
      this.getguige([this.search.id]);
    }
    if (this.search.pid || this.search.id) {
      this.getNextLeimu();
      // 获取品牌
      // this.getPingpai();
    }
  },
  mounted() {
    // 获取列表
    this.changeUpdate();
  },
  methods: {
    changepingpaiListAll() {
      if (this.pingpaiChecked) {
        this.pingpaiList.forEach((item) => {
          item.checked = false;
        });
      }
      this.changeUpdate();
    },
    changepingpaiList() {
      let msg = true;
      this.pingpaiList.forEach((item) => {
        if (item.checked) {
          msg = false;
        }
      });
      this.pingpaiChecked = msg;
      this.changeUpdate();
    },
    // 点击上机类目
    showShangji() {
      this.search.id = "";
      this.search.name = "";
      this.getNextLeimu();
      this.changeUpdate();
    },
    // 对比
    contrast() {
      this.$emit("contrast");
    },
    // 企业
    enterpriseCar() {
      this.$emit("enterpriseCar");
    },
    // 个人
    myCar(val) {
      this.$emit("myCar", val);
    },
    changeshowType(val) {
      if (this.showType == val) return;
      this.showType = val;
      this.$emit("changeshowType", val);
    },
    chengaPrice(val) {
      this.leftSearch.price = val;
      this.changeUpdate();
    },
    changeUpdate() {
      let spec_value_id = [];
      this.guigeList.forEach((item) => {
        item.spec_value.forEach((its) => {
          if (its.checked) {
            spec_value_id.push(its.spec_value_id);
          }
        });
      });

      let brand_id = [];
      if (!this.pingpaiChecked) {
        this.pingpaiList.forEach((item) => {
          if (item.checked) {
            brand_id.push(item.id);
          }
        });
      }
      let shop_category_id = [];
      if (this.search.id) {
        shop_category_id = [this.search.id];
      } else if (this.search.pid) {
        shop_category_id = [this.search.pid];
      }

      let params = {
        company_id: this.$store.state.cid,
        shop_category_id, //商品分类末级分类id，可能多选
        is_new_added: this.leftSearch.news ? 1 : null, //是否勾选最近一个星期上架的商品
        order: {
          price: this.leftSearch.price, //价格，asc=正序desc=倒序
        },
        name: this.search.search || "", //顶部搜索框 商品名称搜索
        spec_value_id, // 规格值id
        brand_id, //商品品牌
      };
      document.title =
        `${this.$store.state.name_logo.name}
        ${this.search.pname?"-"+this.search.pname:""}
        ${this.search.name?"-"+this.search.name:""}
        `
      this.$emit("changeUpdate", params);
    },
    // 更改最新
    changeNew() {
      this.leftSearch.news = !this.leftSearch.news;
      this.changeUpdate();
    },
    // 获取规格
    getguige(shop_category_id) {
      get_spec({
        company_id: this.$store.state.cid,
        shop_category_id,
      }).then((res) => {
        console.log(res);
        this.guigeList = res.data.map((item) => {
          item.guigeOpne = false;
          item.spec_value.forEach((item) => {
            item.checked = false;
          });
          return item;
        });
        console.log(this.guigeList);
      });
    },
    getPingpai() {
      // 获取品牌
      get_brand({ company_id: this.$store.state.cid }).then((res) => {
        this.pingpaiList = res.data;
      });
    },
    getNextLeimu() {
      get_shop_category({
        company_id: this.$store.state.cid,
        shop_category_id: this.search.id || this.search.pid,
      }).then((res) => {
        this.nextLeimu = res.data[0].children || [];
        if (this.nextLeimu.length > 0) {
          let array = [];
          this.nextLeimu.forEach((items) => {
            array.push(items.id);
          });
        }
      });
    },
    leimuShow() {
      let msg = this.search?.pname || "";
      if (this.search.id) {
        // 判断是一级还是二级
        msg += ">" + this.search.name;
      }
      return msg;
    },
    choeThis(item) {
      this.search.id = item.id;
      this.search.name = item.name;
      this.getguige([this.search.id]);
      this.changeUpdate();
      this.nextLeimu = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.othersSearch {
  margin-top: 10px;
  background-color: $white;
  padding: 16px 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  & > div {
    display: flex;
    align-items: center;
  }

  .twos {
    & > i {
      color: $textSecondary;
      margin-left: 18px;
      font-size: 18px;
      cursor: pointer;
      &:hover {
        color: $primary;
      }
    }
  }
}
.searchData {
  background-color: $white;
  padding: 16px 77px;
  & > div {
    display: flex;
    margin-bottom: 14px;
    & > div:nth-child(1) {
      width: 100px;
    }
    & > div:nth-child(2) {
      flex: 1;
      overflow: hidden;
      padding-bottom: 5px;
      border-bottom: 1px solid #eee;
      display: flex;
      & > div:nth-child(1) {
        flex: 1;
        overflow: hidden;
      }
      & > div:nth-child(2) {
        width: 60px;
        text-align: right;
        color: #000;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}

.fixHeighe {
  height: 23px;
}

.nexLeimu {
  margin-right: 16px;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: $primary;
  }
}
.getmore {
  &:hover {
    color: $primary !important;
  }
}

.leftSearch {
  & > span {
    margin-left: 20px;
    user-select: none;
    cursor: pointer;
    i {
      color: $textSecondary;
    }
  }
}

.chose {
  color: $primary !important;
}
.spnLeimu {
  cursor: pointer;
  &:hover {
    color: $primary !important;
  }
}
</style>
